import React from 'react';

function SocialIcon({ link, icon, color }) {
    return (
        <a href={link} target="_blank">
            <div className={`rounded-full p-2 flex items-center ${color}`}>
                <div className={`rounded-full p-1 ${color}`}>
                    {/* <FaFacebookF style={{ fontSize: "12px", color: "#ffffff" }} /> */}
                    {icon}
                </div>
            </div>
        </a>
    );
}

export default SocialIcon;