import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import WhatsAppBubble from "./components/WhatsAppBubble";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

// Lazy-loaded components
const Home = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Gallery = lazy(() => import("./pages/Gallery"));
const ContactUs = lazy(() => import("./pages/ContactUs"));


function App() {
  return (
    <>
      <Suspense fallback={
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      }>
        <Header />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/gallery-section" element={<Gallery />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <WhatsAppBubble phoneNumber="+919966416168 " />
        <Footer />
      </Suspense>
    </>
  );
}

export default App;