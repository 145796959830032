import React from 'react';
import { NavLink } from 'react-bootstrap';
import { FaFacebookF, FaYoutube, FaInstagram, FaMapMarker } from "react-icons/fa";
import SocialIcon from './SocialIcon';

const Footer = () => {
  return (
    <footer className=" py-6 text-white text-center" style={{ padding: "80px 0 20px 0", background: "#fff4e8" }}>
      <div className='container'>
        <div className="flex justify-center items-center mb-10">
          <img src="/images/logo3.png" alt="Logo" className="h-[80px] text-center" />
        </div>
        <div className="flex justify-center mb-4 gap-5">
          <SocialIcon icon={<FaInstagram style={{ fontSize: "16px", color: "#ffffff" }}  />} color={'instagram-bubble'} link="https://www.instagram.com/vamshiconventioncenter" />
          <SocialIcon icon={<FaFacebookF style={{ fontSize: "16px", color: "#ffffff" }}  />} color={'facebook-bubble'} link="https://www.facebook.com/profile.php?id=61556386241041" />
          <SocialIcon icon={<FaYoutube style={{ fontSize: "16px", color: "#ffffff" }}  />} color={'youtube-bubble'} link="https://www.youtube.com/channel/UCd0sfgCG7vUPzRUpBS_QjbA" />
          <SocialIcon icon={<FaMapMarker style={{ fontSize: "16px", color: "#ffffff" }}  />} color={'maps-bubble'} link="https://maps.app.goo.gl/7sMvzt1U8FHYVuZa7" />
        </div>
        <p className="text-xs text-black pt-3">Vamshi connection Center @2024 All Rights Reserved. Designed by Shift2digital Cloud Solutions Pvt Ltd.</p>
      </div>
    </footer>
  );
};

export default Footer;
