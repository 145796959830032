import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import "./WhatsAppBubble.css"

const WhatsAppBubble = ({ phoneNumber }) => {
    return (
        <div className="whatsapp-bubble">
            <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                <FaWhatsapp size={30} color="#fff" />
            </a>
        </div>
    );
};

export default WhatsAppBubble;