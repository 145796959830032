import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import Button from "./Button";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const menuButtonRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document?.addEventListener("click", handleClickOutside);
    }

    return () => {
      document?.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="sticky top-0 z-50 bg-white w-full" id="navbar">
      <nav className="p-4 flex items-center justify-between flex-wrap" style={{borderBottom: "1px solid #d97706"}}>
        {/* Sidebar */}
        {isOpen && (
          <div
            className="fixed inset-0 z-50 bg-gray-800 bg-opacity-50 lg:hidden"
            ref={sidebarRef}
          >
            <div className="flex flex-col w-64 bg-white h-full">
              {/* Close icon */}
              <div className="flex justify-between p-4">

                <NavLink to={"/"}>
                  <div className="flex justify-stat flex-1">
                    <img src="/images/logo2.png" alt="Logo" className="h-8" />
                  </div>
                </NavLink>
                <button
                  className="text-gray-500 hover:text-gray-600 focus:outline-none"
                  onClick={toggleMenu}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>

              <div className="px-4">
                <NavLink
                  to={"/"} onClick={toggleMenu} 
                  activeClassName="active"
                  className="text-decoration-none text-customText hover:text-gray-300 font-inter font-medium text-base py-2 block"
                >
                  Home
                </NavLink>
                <NavLink  onClick={toggleMenu} 
                  to={"/about-us"}
                  activeClassName="active"
                  className="text-decoration-none text-customText hover:text-gray-300 font-inter font-medium text-base py-2 block"
                >
                  About Us
                </NavLink>
                {/* Gallery */}
                <NavLink  onClick={toggleMenu} 
                  to={"/gallery-section"}
                  activeClassName="active"
                  className="text-decoration-none text-customText hover:text-gray-300 font-inter font-medium text-base py-2 block"
                >
                  Gallery
                </NavLink>
                {/* Contact Us */}
                <NavLink  onClick={toggleMenu} 
                  to={"/contact-us"}
                  activeClassName="active"
                  className="text-decoration-none text-customText hover:text-gray-300 font-inter font-medium text-base py-2 block">
                  Contact Us
                </NavLink>
              </div>

            </div>
            <NavLink to={"/"}>
              <div className="ml-130 flex items-center justify-center flex-1 mr-[25vh] " >
                <img src="/images/logo2.png" alt="Logo" className="h-8" />
              </div>
            </NavLink>
          </div>
        )}

        {/* Menu items */}
        <div className="hidden lg:flex items-center space-x-4">
          <div className="flex space-x-4 lg:flex space-x-8">
            <NavLink
              to={"/"}  onClick={toggleMenu} 
              activeClassName="active"
              className="text-decoration-none text-customText hover:text-gray-300 font-inter font-medium text-base"
            >
              Home
            </NavLink>
            <NavLink onClick={toggleMenu} 
              to={"/about-us"}
              activeClassName="active"
              className="text-decoration-none text-customText hover:text-gray-300 font-inter font-medium text-base"
            >
              About Us
            </NavLink>

            <NavLink onClick={toggleMenu} 
              to={"/gallery-section"}
              activeClassName="active"
              className="text-decoration-none text-customText hover:text-gray-300 font-inter font-medium text-base"
            >
              Gallery
            </NavLink>

            <NavLink onClick={toggleMenu} 
              to={"/contact-us"}
              activeClassName="active"
              className="text-decoration-none text-customText hover:text-gray-300 font-inter font-medium text-base block">
              Contact Us
            </NavLink>
          </div>
        </div>
        <NavLink to={"/"}>
          <div className="ml-130 flex items-center justify-center flex-1">
            <img src="/images/logo2.png" alt="Logo" className="h-8" />
          </div>
        </NavLink>

        {/* Logo and Menu icon */}
        <div className="flex items-center justify-between px-4 lg:hidden">
          {/* Burger icon */}
          <div className="flex items-center justify-start">
            <button
              className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600 lg:hidden"
              onClick={toggleMenu}
              ref={menuButtonRef}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
        {/* Button at the end */}
        <div className="flex items-center justify-end w-full lg:w-auto hidden lg:flex">
          <Button text="Send Request" link="/contact-us" />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
