import React from 'react';

import { NavLink } from "react-router-dom";

function Button({ text, link }) {
    return (
        <NavLink to={link}>
            <button className="text-white py-2 px-5 bg-brown hover:bg-green ">
                {text}
            </button>
        </NavLink>
    );
}

export default Button;